.label {
	text-transform: capitalize;
}

.header {
	position: sticky;
	top: 0;
	z-index: 1000;
}

.hero {
	background-color: #ebebeb;
}

.error {
	color: red;
}
.line {
	height: 2px;
	margin-top: 10px;
	border-width: 0;
	color: gray;
	background-color: gray;
}
