.chart-container {
position: relative;
width: 100%;
padding-bottom: 50%; /* For 2:1 aspect ratio, use 50% for 2:3, use 66.67%, etc. */
}

.chart-wrapper {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}